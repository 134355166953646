<script>
  import { Carousel3d, Slide } from "vue-carousel-3d";
  export default {
    props: ["best", "awards", "subtitle", "modal", "statusRange"],
    components: {
      Carousel3d,
      Slide,
      UsersListVisor: () => import("../widgets/Modals/UsersListVisor.vue"),
    },
    data() {
      return {
        slides: [],
        showModalViewers: false,
        participantViewers: [],
      };
    },
    watch: {
      best(newValue) {
        this.slides = newValue;
      },
    },
    computed: {
      slidesCarousel() {
        return this.slides;
      },
      spaceCards() {
        if (this.$screen.width > 1180) {
          return this.spaceToDesk();
        } else {
          return this.spaceToTable();
        }
      },
      userIsAdmin() {
        return this.$userData.role === "superadmin";
      },
    },
    methods: {
      showdetails(item, idx) {
        this.$emit("showModalDetails", item, this.getPosition(idx), true);
      },
      getIcon(position) {
        if (position === 0) {
          return "https://new-web-site-bucket.s3.amazonaws.com/myspace/concursos/1mer.png";
        } else if (position === 2) {
          return "https://new-web-site-bucket.s3.amazonaws.com/myspace/concursos/3er.png";
        } else if (position === 1) {
          return "https://new-web-site-bucket.s3.amazonaws.com/myspace/concursos/2do.png";
        }
      },
      getPosition(position) {
        return position;
      },
      awarPosition(position) {
        const awards = this.awards || [];

        if (!awards.length) {
          return;
        }
        if (this?.statusRange) {
          position++;
          const result = awards.find((element) => position >= element.positionStart && position <= element.positionEnd);
          return result.logo;
        } else {
          if (awards && position >= 0 && position < awards.length) {
            return awards[position]?.logo;
          }
        }
      },
      pictureImage(user) {
        return user?.internalPicture;
      },
      cityOfficeUser(user) {
        const { city } = user;
        return `${city}`;
      },
      nameUser(item) {
        const { artisticName, user } = item;
        return artisticName || user;
      },
      spaceToDesk() {
        const initBreakPoint = 1490;
        const valueToLess = initBreakPoint - this.$screen.width;
        const refValue = 550;
        const newValue = refValue - valueToLess / 1.4;
        return valueToLess < 1 ? refValue : newValue;
      },
      spaceToTable() {
        const initBreakPoint = 1180;
        const valueToLess = initBreakPoint - this.$screen.width;
        const refValue = 540;

        const newValue = refValue - valueToLess / 1.2;
        return valueToLess < 1 ? refValue : newValue;
      },
      showModal(users) {
        this.showModalViewers = !this.showModalViewers;
        if (!this.showModalViewers) {
          this.participantViewers = [];
        } else {
          this.participantViewers = users;
        }
      },
      getParticipantViewers({ viewers = [] }) {
        return viewers.map(({ user, views }) => ({ ...user, views }));
      },
    },
    async mounted() {
      this.slides = this.best;
      this.$refs.mycarousel.goSlide(1);
    },
  };
</script>

<template>
  <div class="competitionCarousel">
    <p class="competitionCarousel__subtitleCompetition">
      {{ subtitle }}
    </p>
    <carousel-3d
      ref="mycarousel"
      :key="slides.length"
      :autoplay="true"
      :autoplay-timeout="4000"
      :loop="true"
      :autoplayHoverPause="true"
      :height="350"
      :width="500"
      :space="spaceCards"
      :perspective="10">
      <slide v-for="(item, i) in slidesCarousel" :index="i" :key="i" class="competitionCarousel__slide">
        <figure class="competitionCarousel__slideFigure">
          <img :src="pictureImage(item)" :alt="pictureImage(item)" class="competitionCarousel__slideImage" />
        </figure>
        <div class="competitionCarousel__descriptionContainer">
          <img v-if="awarPosition(i)" :src="awarPosition(i)" :alt="awarPosition(i)" class="competitionCarousel__carouselAwardIcon" />
          <div class="competitionCarousel__descriptionSubContainer">
            <img :src="getIcon(i)" :alt="getIcon(i)" class="competitionCarousel__carouselPositionIcon" />
            <div class="competitionCarousel__carouselAwardInformation">
              <p class="competitionCarousel__carouselAwardName">{{ nameUser(item.user) }}</p>
              <div class="competitionCarousel__contentDetails">
                <span class="competitionCarousel__corouselAwarCountry">{{ cityOfficeUser(item.user) }}</span>
                <div class="competitionCarousel__views" @click="showModal(getParticipantViewers(item))" v-if="getParticipantViewers(item).length && userIsAdmin && modal">
                  <iconic class="competitionCarousel__eye" name="eye_contets" />
                  <p>{{ getParticipantViewers(item).length }}</p>
                </div>
              </div>
            </div>
            <div v-show="modal" class="competitionCarousel__carouselModal" @click="() => showdetails(item, i)">
              <iconic name="eye_contets" class="competition__searchIcon" />
            </div>
          </div>
        </div>
      </slide>
    </carousel-3d>
    <UsersListVisor :users="participantViewers" :detail="'Usuarios que han visto la participante:'" :closeModal="showModal" v-if="showModalViewers" />
  </div>
</template>

<style lang="scss">
  .competitionCarousel {
    padding-top: 30px;
    text-align: left;
    align-items: left;
    &__contentDetails {
      @include Flex(row);
      gap: 10px;
    }
    &__views {
      @include Flex(row);
      color: $white;
      gap: 10px;
      padding: 0 6px;
      font-size: 12px;
      font-weight: 600;
      border-radius: 15px;
      background-color: rgba(0, 0, 0, 0.4);
      cursor: pointer;
    }
    &__eye {
      font-size: 14px;
    }
    &__subtitleCompetition {
      font-family: $primary_font;
      font-style: italic;
      text-transform: uppercase;
      font-weight: 600;
    }
    &__slide {
      border-radius: 11px;
      border: none;
      cursor: pointer;
      box-shadow: 0 1px 4px rgba(99, 99, 99, 0.6);
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 28%;
        width: 100%;
        border-radius: 0 0 10px 10px;
        background-image: linear-gradient(0deg, #161616 22%, rgba(15, 15, 15, 0) 81%, rgba(19, 19, 19, 0.0452556));
      }
    }
    &__slideFigure {
      position: relative;
      margin: 0;
    }
    &__slideImage {
      height: 100%;
    }

    &__descriptionContainer {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
    }

    &__descriptionSubContainer {
      position: relative;
      width: 100%;
      height: 100%;
    }
    &__carouselAwardInformation {
      position: absolute;
      @include Flex(column, flex-start, flex-start);
      bottom: 0.8rem;
      left: 1rem;
    }
    &__carouselModal {
      position: absolute;
      @include Flex(column, flex-end, flex-end);
      bottom: 0.8rem;
      right: 1rem;
      & .competition__searchIcon {
        padding: 0.5rem;
        margin-left: 10px;
        border-radius: 50%;
        color: #fff;
        cursor: pointer;
        transition: all 0.4s ease;
        font-weight: bold;
        font-size: 15px;
        &:hover {
          background-color: rgba(189, 9, 9, 0.7);
          transform: scale(1.2);
        }
      }
    }
    &__carouselPositionIcon {
      position: absolute;
      top: 1rem;
      left: 1rem;
      max-width: 53px;
      max-height: 53px;
      border-radius: 50%;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.82);
    }
    &__carouselAwardName {
      font-family: $sec_font;
      color: #fff;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
      font-weight: 400;
      line-height: 1.4rem;
      font-size: 22px;
    }

    &__carouselAwardIcon {
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 45px;
      max-width: 45px;
      height: 45px;
    }

    &__corouselAwarCountry {
      color: #fff;
      font-size: 14px;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
    }

    .right-1 > .competition__descriptionContainer > .competition__descriptionSubContainer > .competition__carouselAwardInformation {
      position: absolute;
      @include Flex(column, flex-start, flex-end);
      right: 1rem;
      left: auto;
      bottom: 1rem;
    }

    .right-1 > .competition__descriptionContainer > .competition__descriptionSubContainer > &__carouselPositionIcon {
      position: absolute;
      left: auto;
      top: 1rem;
    }

    .prev {
      color: #000;
    }
    .next {
      color: #000;
    }
  }
</style>
